<template>
    <div class="basic_data_edit">
        <div class="data_content scrollbar">
            <!-- 按钮 -->
            <div class="botton_box">
                <span class="menu_title">{{ title }}</span>
                <div class="button_list">
                    <sn-button
                        :snButton="snButton"
                        :class="{ showButton: !saveButton }"
                        @handleChange="handleChange"
                    ></sn-button>
                </div>
            </div>
            <!-- 表单 -->
            <el-form
                ref="formdata"
                :inline="true"
                :model="formdata.dataDic"
                :rules="rules"
                class="demo-form-inline form_row"
                size="small"
                label-width="200px"
            >
                <el-form-item prop="ND" label="年度" :style="{ width: '80%' }">
                    <el-date-picker
                        v-model="formdata.dataDic.ND"
                        type="year"
                        placeholder="选择年份"
                        value-format="yyyy"
                        :disabled="!isEdit"
                    >
                    </el-date-picker>
                </el-form-item>

                <el-form-item
                    label="茶叶产量"
                    prop="CYCL"
                    :style="{ width: '80%' }"
                >
                    <el-input
                        v-model="formdata.dataDic.CYCL"
                        :disabled="!isEdit"
                        placeholder="请填写"
                    >
                        <template slot="append">公斤</template>
                    </el-input>
                </el-form-item>
                <el-form-item
                    label="茶叶质量"
                    prop="CYZL"
                    :style="{ width: '80%' }"
                >
                    <el-input
                        v-model="formdata.dataDic.CYZL"
                        :disabled="!isEdit"
                        placeholder="请填写"
                    >
                    </el-input>
                </el-form-item>
                <el-form-item
                    label="茶叶品种"
                    prop="CYPZ"
                    :style="{ width: '80%' }"
                >
                    <el-input
                        v-model="formdata.dataDic.CYPZ"
                        :disabled="!isEdit"
                        placeholder="请填写"
                    >
                    </el-input>
                </el-form-item>

                <el-form-item
                    label="茶叶价格"
                    prop="CYJG"
                    :style="{ width: '80%' }"
                >
                    <el-input
                        v-model="formdata.dataDic.CYJG"
                        :disabled="!isEdit"
                        placeholder="请填写"
                    >
                        <template slot="append">元</template>
                    </el-input>
                </el-form-item>
                <el-form-item
                    label="茶叶收益"
                    prop="CYSY"
                    :style="{ width: '80%' }"
                >
                    <el-input
                        v-model="formdata.dataDic.CYSY"
                        :disabled="!isEdit"
                        placeholder="请填写"
                        ><template slot="append">元</template>
                    </el-input>
                </el-form-item>
                <el-form-item
                    label="茶产业从业人员数量"
                    prop="CYRY"
                    :style="{ width: '80%' }"
                >
                    <el-input
                        v-model="formdata.dataDic.CYRY"
                        :disabled="!isEdit"
                        placeholder="请填写"
                    >
                        <template slot="append">人</template>
                    </el-input>
                </el-form-item>
                <el-form-item
                    label="合作社数量"
                    prop="HZSSL"
                    :style="{ width: '80%' }"
                >
                    <el-input
                        v-model="formdata.dataDic.HZSSL"
                        :disabled="!isEdit"
                        placeholder="请填写"
                    >
                    </el-input>
                </el-form-item>
                <el-form-item
                    label="生产规模"
                    prop="SCGM"
                    :style="{ width: '80%' }"
                >
                    <el-input
                        v-model="formdata.dataDic.SCGM"
                        :disabled="!isEdit"
                        placeholder="请填写"
                    >
                    </el-input>
                </el-form-item>

                <el-form-item
                    label="人员总数"
                    prop="RYSL"
                    :style="{ width: '80%' }"
                >
                    <el-input
                        v-model="formdata.dataDic.RYSL"
                        :disabled="!isEdit"
                        placeholder="请填写"
                    >
                    </el-input>
                </el-form-item>
                <el-form-item label="品牌" prop="PP" :style="{ width: '80%' }">
                    <el-input
                        v-model="formdata.dataDic.PP"
                        :disabled="!isEdit"
                        placeholder="请填写"
                    >
                    </el-input>
                </el-form-item>
            </el-form>
        </div>
    </div>
</template>

<script>
import infoMixin from "../0_com_js/info_mixin.js";
export default {
    mixins: [infoMixin],
    data() {
        return {
            title: "",
            isEdit: true,
            saveButton: true,
            formdata: {
                dataDic: {
                    glycbtid: this.heritageId,
                    ND: null, //年度
                    CYCL: null, //茶叶产量
                    CYZL: null, //茶叶质量
                    CYPZ: null, //茶叶品种
                    CYJG: null, //茶叶价格(元)
                    CYSY: null, //茶叶收益(元)
                    CYRY: null, //茶产业从业人员数量(人)
                    HZSSL: null, //合作社数量
                    SCGM: null, //生产规模
                    RYSL: null, //人员总数
                    PP: null, //品牌
                },
                fileids: "",
                itemCode: "1008",
                metaJson: [],
            },
            rules: {
                ND: [
                    {
                        required: true,
                        message: "请选择",
                        trigger: ["change"],
                    },
                ],
                CYCL: [
                    {
                        required: true,
                        message: "请选择",
                        trigger: ["change"],
                    },
                ],
            },
        };
    },
    watch: {},
    mounted() {
        this.title = this.$route.meta.title;
    },
    methods: {
        GetDataNext() {
            this.formdata.dataDic.ND = String(this.formdata.dataDic.ND);
        },
    },
};
</script>

<style></style>
